import React, { useRef, useEffect } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import * as THREE from "three";

const AvatarModel = (props) => {
  const group = useRef();
  const mixer = useRef(); // Reference to the animation mixer
  const fbx = useLoader(FBXLoader, props.file);

  // Load the textures
  const albedoMap = useLoader(THREE.TextureLoader, "/m2/m2_refine_albedo2.jpeg");
  const normalMap = useLoader(THREE.TextureLoader, "/m3/m2_refine_normal.jpg");
  const roughnessMap = useLoader(THREE.TextureLoader, "/m3/m2_refine_roughness.jpg");
  const aoMap = useLoader(THREE.TextureLoader, "/m3/m2_refine_ao.jpg");
  const heightMap = useLoader(THREE.TextureLoader, "/m3/m2_refine_height.jpg");

  // All your constants remain intact
  const NORMAL_INTENSITY = 0.001;
  const ROUGHNESS_INTENSITY = 10;
  const AO_INTENSITY = 1;
  const HEIGHT_INTENSITY = 0.001;
  const ALBEDO_STRENGTH = 0.5;
  const ALBEDO_TRANSPARENCY = 0.5;
  
  // Light timing variables
  const LIGHT_1_SPEED = 0.25;
  const LIGHT_2_SPEED = 0.25;
  const LIGHT_3_SPEED = 0.25;
  const LIGHT_4_SPEED = 0.25;
  
  const LIGHT_INTENSITY_1 = 1.75;
  const LIGHT_INTENSITY_2 = 0.25;
  const LIGHT_INTENSITY_3 = 1.75;
  const LIGHT_INTENSITY_4 = 0.25;
  
  const LIGHT_DISTANCE = 3;
  
  // Light rotation direction (1 for clockwise, -1 for counter-clockwise)
  const LIGHT_1_DIRECTION = 1;
  const LIGHT_2_DIRECTION = 1;
  const LIGHT_3_DIRECTION = 1;
  const LIGHT_4_DIRECTION = 1;
  
  // Crystal gold effect intensity
  const CRYSTAL_INTENSITY = 0.001;

  // Shader material using uniforms
  const shaderMaterial = new THREE.ShaderMaterial({
    uniforms: {
      albedoMap: { value: albedoMap },
      normalMap: { value: normalMap },
      roughnessMap: { value: roughnessMap },
      aoMap: { value: aoMap },
      heightMap: { value: heightMap },
      normalIntensity: { value: NORMAL_INTENSITY },
      roughnessIntensity: { value: ROUGHNESS_INTENSITY },
      aoIntensity: { value: AO_INTENSITY },
      heightIntensity: { value: HEIGHT_INTENSITY },
      albedoStrength: { value: ALBEDO_STRENGTH },
      albedoTransparency: { value: ALBEDO_TRANSPARENCY },
      light1Speed: { value: LIGHT_1_SPEED },
      light2Speed: { value: LIGHT_2_SPEED },
      light3Speed: { value: LIGHT_3_SPEED },
      light4Speed: { value: LIGHT_4_SPEED },
      light1Intensity: { value: LIGHT_INTENSITY_1 },
      light2Intensity: { value: LIGHT_INTENSITY_2 },
      light3Intensity: { value: LIGHT_INTENSITY_3 },
      light4Intensity: { value: LIGHT_INTENSITY_4 },
      light1Direction: { value: LIGHT_1_DIRECTION },
      light2Direction: { value: LIGHT_2_DIRECTION },
      light3Direction: { value: LIGHT_3_DIRECTION },
      light4Direction: { value: LIGHT_4_DIRECTION },
      lightDistance: { value: LIGHT_DISTANCE },
      time: { value: 0 },
      crystalIntensity: { value: CRYSTAL_INTENSITY },
    },
    vertexShader: `
      varying vec3 vNormal;
      varying vec3 vPosition;

      void main() {
        vNormal = normalize(normalMatrix * normal);
        vPosition = position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D albedoMap;
      uniform sampler2D normalMap;
      uniform sampler2D roughnessMap;
      uniform sampler2D aoMap;
      uniform sampler2D heightMap;
      uniform float normalIntensity;
      uniform float roughnessIntensity;
      uniform float aoIntensity;
      uniform float heightIntensity;
      uniform float albedoStrength;
      uniform float albedoTransparency;
      uniform float time;
      uniform float light1Speed;
      uniform float light2Speed;
      uniform float light3Speed;
      uniform float light4Speed;
      uniform float light1Intensity;
      uniform float light2Intensity;
      uniform float light3Intensity;
      uniform float light4Intensity;
      uniform float light1Direction;
      uniform float light2Direction;
      uniform float light3Direction;
      uniform float light4Direction;
      uniform float lightDistance;
      uniform float crystalIntensity;

      varying vec3 vNormal;
      varying vec3 vPosition;

      vec3 calculateLight(vec3 normal, float angle, float distance, float height, float intensity) {
        vec3 lightDir = normalize(vec3(cos(angle) * distance, height, sin(angle) * distance));
        return max(dot(normal, lightDir), 0.0) * intensity * vec3(1.0, 0.98, 0.95);
      }

      void main() { 
        vec4 albedoColor = texture2D(albedoMap, vec2(vPosition.x, vPosition.z));
        float alpha = albedoColor.a * albedoTransparency;

        vec3 albedo = albedoColor.rgb * albedoStrength;
        vec3 normalTex = normalize(texture2D(normalMap, vec2(vPosition.x, vPosition.z)).rgb * 2.0 - 1.0) * normalIntensity;
        vec3 normal = normalize(vNormal + normalTex);

        vec3 light1 = calculateLight(normal, light1Direction * time * light1Speed + 0.0, lightDistance, 0.1, light1Intensity);
        vec3 light2 = calculateLight(normal, light2Direction * time * light2Speed + 1.57079632679, lightDistance, 1.5, light2Intensity);
        vec3 light3 = calculateLight(normal, light3Direction * time * light3Speed + 3.14159265359, lightDistance, 1.0, light3Intensity);
        vec3 light4 = calculateLight(normal, light4Direction * time * light4Speed + 4.71238898038, lightDistance, 1.5, light4Intensity);
        vec3 lighting = (light1 + light2 + light3 + light4);

        vec3 finalColor = albedo * (lighting + 0.2);

        gl_FragColor = vec4(finalColor, alpha);
      }
    `,
    wireframe: false,
  });

  // Load and assign the shader material
  useEffect(() => {
    fbx.traverse((node) => {
      if (node.isMesh && node.material.map) {
        node.material = shaderMaterial;
      }
    });
  }, [fbx]);

  // Initialize and play animation
  useEffect(() => {
    if (fbx.animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(fbx);
      const action = mixer.current.clipAction(fbx.animations[0]);
      action.play();
    }
  }, [fbx]);

  // Update animation frames and add avatar rotation
  useFrame((state, delta) => {
    if (mixer.current) {
      mixer.current.update(delta);
    }
    // Rotate the avatar around its Y-axis
    if (fbx) {
      fbx.rotation.y += 0.01; // Adjust rotation speed as needed
    }
    shaderMaterial.uniforms.time.value = state.clock.getElapsedTime(); // Update time for rotating lights
  });

  // Return the model with ambient and directional lighting for visibility
  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} intensity={1} />
      <primitive object={fbx} ref={group} {...props} />
    </>
  );
};

export default AvatarModel;
