import React, { useState } from 'react';
import BodySelectorComponent from './BodySelectorComponent';

const avatars = [
  { id: 'avatar0', file: '/m5/n0.fbx', label: 'Type 1' },
  { id: 'avatar1', file: '/m5/n1.fbx', label: 'Type 2' },
  { id: 'avatar2', file: '/m5/n2.fbx', label: 'Type 3' },
  { id: 'avatar3', file: '/m5/n3.fbx', label: 'Type 4' },
  { id: 'avatar4', file: '/m5/n4.fbx', label: 'Type 5' },
  //{ id: 'avatar5', file: '/m5/n5.fbx', label: 'Type 6' },
 // { id: 'avatar6', file: '/m5/n6.fbx', label: 'Type 7' },
];

const BodyTypeSelector = () => {
  const [selectedBodyType, setSelectedBodyType] = useState(null);

  const handleBodyTypeSelect = (index) => {
    setSelectedBodyType(avatars[index]);
    // You can add any additional logic here, such as sending the selection to a parent component or an API
  };

  return (
    <div className="body-type-selector">
      <h2>Select Your Body Type</h2>
      <BodySelectorComponent avatars={avatars} onSelectAvatar={handleBodyTypeSelect} />
      {selectedBodyType && (
        <div className="selected-body-type">
          <h3>Selected Body Type: {selectedBodyType.label}</h3>
          {/* You can add more details or actions related to the selected body type here */}
        </div>
      )}
    </div>
  );
};

export default BodyTypeSelector;