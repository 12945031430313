import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import axios from 'axios';

const GEONAMES_USERNAME = "zmotion"; // Replace with your GeoNames username

const GetCity = ({ register, errors, setValue }) => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  // Fetch countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('/dat/dropdown.json');
        const countriesList = response.data.countriesList || [];
        setCountries(countriesList);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    fetchCountries();
  }, []);

  // Fetch cities when country changes
  useEffect(() => {
    const fetchCities = async () => {
      if (selectedCountry) {
        try {
          const countryCode = countries.find(c => c.name === selectedCountry)?.code;
          const response = await axios.get(
            `http://api.geonames.org/searchJSON?country=${countryCode}&featureClass=P&maxRows=10&username=${GEONAMES_USERNAME}`
          );
          if (response.data && response.data.geonames) {
            setCities(response.data.geonames);
          } else {
            setCities([]);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
          setCities([]);
        }
      } else {
        setCities([]);
      }
      setValue("city", '');
    };
    fetchCities();
  }, [selectedCountry, countries, setValue]);

  return (
    <Box display="flex" justifyContent="space-between">
      {/* Country Select */}
      <FormControl fullWidth sx={{ marginRight: 1, backgroundColor: "#333", marginBottom: "20px" }}>
        <InputLabel style={{ color: "grey" }}>Country</InputLabel>
        <Select
          {...register("country", { required: "Country is required" })}
          error={!!errors.country}
          sx={{ color: "white" }}
          value={selectedCountry}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedCountry(value);
            setValue("country", value, { shouldValidate: true });
          }}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* City Select */}
      <FormControl fullWidth sx={{ backgroundColor: "#333", marginBottom: "20px" }}>
        <InputLabel style={{ color: "grey" }}>City</InputLabel>
        <Select
          {...register("city", { required: "City is required" })}
          error={!!errors.city}
          sx={{ color: "white" }}
          onChange={(e) => {
            setValue("city", e.target.value, { shouldValidate: true });
          }}
        >
          {cities.length > 0 ? (
            cities.map((city) => (
              <MenuItem key={city.geonameId} value={city.name}>
                {city.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              {selectedCountry ? "No cities available" : "Select a country first"}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(GetCity);