import React, { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import AvatarModel from './AvatarModel';

const WHEEL_RADIUS = 2;
const AVATAR_SCALE = 0.005;

const AvatarWheel = ({ avatars }) => {
  const wheelRef = useRef();
  const [rotation, setRotation] = useState(0);
  const speedRef = useRef(0);

  useFrame(() => {
    if (wheelRef.current) {
      setRotation(prev => prev + speedRef.current);
      wheelRef.current.rotation.z = rotation;
      speedRef.current *= 0.99; // Very slight friction
    }
  });

  const handlePointerDown = (e) => {
    e.target.setPointerCapture(e.pointerId);
  };

  const handlePointerUp = (e) => {
    e.target.releasePointerCapture(e.pointerId);
  };

  const handlePointerMove = (e) => {
    if (e.buttons > 0) {
      speedRef.current -= e.movementX * 0.01;
    }
  };

  return (
    <group 
      ref={wheelRef}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      {avatars.map((avatar, index) => {
        const angle = (index / avatars.length) * Math.PI * 2;
        const x = Math.sin(angle) * WHEEL_RADIUS;
        const y = Math.cos(angle) * WHEEL_RADIUS;
        return (
          <group key={avatar.id} position={[x, y, 0]}>
            <AvatarModel file={avatar.file} scale={[AVATAR_SCALE, AVATAR_SCALE, AVATAR_SCALE]} />
          </group>
        );
      })}
    </group>
  );
};

const BodyTypeWheel = ({ avatars }) => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas camera={{ position: [0, 0, 8], fov: 50 }}>
        <color attach="background" args={['black']} />
        <ambientLight intensity={0.5} />
        <AvatarWheel avatars={avatars} />
      </Canvas>
    </div>
  );
};

export default BodyTypeWheel;