export const mockData = [
    {
      image: '/models/image1.png',
      name: 'John Doe',
      age: 28,
      city: 'New York',
      country: 'USA',
      email: 'john@example.com',
      phone: '+1 234 567 8900',
      instagram: '@johndoe',
      twitter: '@johnd',
      tiktok: '@johndoetiktok',
      campaign: 'Summer 2025',
      engagement: '2023-06-15',
      status: 'Partial Submission',
      adminStatus: 'Under Review',
      notes: 'Candidate shows potential. Follow up on portfolio submission.',
    },
    {
      image: '/models/image2.png',
      name: 'Jane Smith',
      age: 24,
      city: 'Los Angeles',
      country: 'USA',
      email: 'jane@example.com',
      phone: '+1 987 654 3210',
      instagram: '@janesmith',
      twitter: '@janes',
      tiktok: '@janesmithtiktok',
      campaign: 'Fall Collection 2024',
      engagement: '2023-07-22',
      status: 'Completed Submission',
      adminStatus: 'Approved',
      notes: 'Strong portfolio. Schedule for next shoot.',
    },
    {
      image: '/models/image3.png',
      name: 'Michael Johnson',
      age: 30,
      city: 'Chicago',
      country: 'USA',
      email: 'michael@example.com',
      phone: '+1 555 123 4567',
      instagram: '@michaelj',
      twitter: '@mjohnson',
      tiktok: '@michaeljohnsontiktok',
      campaign: 'Winter Wonderland',
      engagement: '2023-08-05',
      status: 'Initial Contact',
      adminStatus: 'Pending',
      notes: 'Reached out via email. Awaiting response.',
    },
    {
      image: '/models/image4.png',
      name: 'Emily Brown',
      age: 26,
      city: 'Miami',
      country: 'USA',
      email: 'emily@example.com',
      phone: '+1 305 789 0123',
      instagram: '@emilybrown',
      twitter: '@emilyb',
      tiktok: '@emilybtiktok',
      campaign: 'Spring Breeze 2025',
      engagement: '2023-09-10',
      status: 'In Negotiation',
      adminStatus: 'In Progress',
      notes: 'Discussing contract terms. Follow up next week.',
    },
    {
      image: '/models/image5.png',
      name: 'David Lee',
      age: 29,
      city: 'San Francisco',
      country: 'USA',
      email: 'david@example.com',
      phone: '+1 415 567 8901',
      instagram: '@davidlee',
      twitter: '@dlee',
      tiktok: '@davidleetiktok',
      campaign: 'Tech Chic 2024',
      engagement: '2023-10-01',
      status: 'Completed Submission',
      adminStatus: 'Under Review',
      notes: 'Impressive tech-focused portfolio. Consider for AI campaign.',
    },
    {
      image: '/models/image3.png',
      name: 'Sophia Garcia',
      age: 23,
      city: 'Houston',
      country: 'USA',
      email: 'sophia@example.com',
      phone: '+1 713 234 5678',
      instagram: '@sophiag',
      twitter: '@sophiagarcia',
      tiktok: '@sophiagtiktok',
      campaign: 'Summer Glow 2025',
      engagement: '2023-11-15',
      status: 'Partial Submission',
      adminStatus: 'Pending',
      notes: 'Missing some required documents. Send reminder.',
    },
    {
      image: '/models/image7.png',
      name: 'Daniel Wilson',
      age: 31,
      city: 'Seattle',
      country: 'USA',
      email: 'daniel@example.com',
      phone: '+1 206 789 0123',
      instagram: '@danielw',
      twitter: '@dwilson',
      tiktok: '@danielwilsontiktok',
      campaign: 'Urban Explorer 2024',
      engagement: '2023-12-05',
      status: 'Completed Submission',
      adminStatus: 'Approved',
      notes: 'Great urban style. Perfect fit for the campaign.',
    },
    {
      image: '/models/image3.png',
      name: 'Olivia Taylor',
      age: 25,
      city: 'Boston',
      country: 'USA',
      email: 'olivia@example.com',
      phone: '+1 617 345 6789',
      instagram: '@oliviat',
      twitter: '@otaylor',
      tiktok: '@oliviataylortiktok',
      campaign: 'Autumn Breeze 2024',
      engagement: '2024-01-10',
      status: 'In Negotiation',
      adminStatus: 'In Progress',
      notes: 'Discussing availability for campaign dates. Follow up needed.',
    },
    {
      image: '/models/image4.png',
      name: 'Ethan Martinez',
      age: 27,
      city: 'Denver',
      country: 'USA',
      email: 'ethan@example.com',
      phone: '+1 303 901 2345',
      instagram: '@ethanm',
      twitter: '@emartinez',
      tiktok: '@ethanmartineztiktok',
      campaign: 'Mountain Spirit 2025',
      engagement: '2024-02-20',
      status: 'Initial Contact',
      adminStatus: 'Pending',
      notes: 'Showed interest via Instagram. Send formal invitation.',
    }
  ];