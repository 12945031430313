import React from 'react'; 
import { useNavigate } from 'react-router-dom';
import BodyTypeSelector from './applicant/BodyTypeSelector';

// Define the MenuPage Component
const MenuPage = ({ 
  textSettings = { textY: 50, textX: 50 }, // Default values
  menuSettings = { menuY: 10, menuX: 10 }, // Default values
  showMenu = true, // Default to showing menu
  responsiveTextSize = 24, // Default text size
  handlePageClick = () => {} // Default to empty function
}) => {
  const navigate = useNavigate();

  // Button styling
  const buttonStyle = {
    padding: '10px 15px',
    margin: '10px 0',
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div 
      className="section-home-header" 
      style={{ 
        width: '100vw', 
        height: '100vh', 
        position: 'relative', 
        backgroundColor: 'black', 
        color: 'white', 
        overflow: 'hidden' 
      }}
      onClick={handlePageClick}
    >
      {/* Display BodyTypeSelector */}
      <BodyTypeSelector />

      {/* Header for Model Search */}
      <h1 style={{
        position: 'absolute',
        top: `${textSettings.textY}%`,
        left: `${textSettings.textX}%`,
        zIndex: 10,
        color: 'white',
        fontSize: `${responsiveTextSize}px`,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '80%',
      }}>
        Model Search 2025!
      </h1>

      {/* Conditional rendering for the menu */}
      {showMenu && (
        <div style={{
          position: 'absolute',
          bottom: `${menuSettings.menuY}px`,
          left: `${menuSettings.menuX}px`,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          padding: '20px',
          borderRadius: '10px',
          zIndex: 20,
        }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            {/* Applicant Section */}
            <div>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Applicant</h3>
              <button style={buttonStyle} onClick={() => navigate('/applicant/register')}>Registration</button>
              <button style={buttonStyle} onClick={() => navigate('/event-info')}>Event Info</button>
              <button style={buttonStyle} onClick={() => navigate('/gallery')}>Gallery</button>
            </div>

            {/* Admin Section */}
            <div>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Admin</h3>
              <button style={buttonStyle} onClick={() => navigate('/admin/dashboard')}>Admin Dashboard</button>
              <button style={buttonStyle} onClick={() => navigate('/admin/applicants')}>Applicant Manager</button>
              <button style={buttonStyle} onClick={() => navigate('/admin/reports')}>Reports</button>
              <button style={buttonStyle} onClick={() => navigate('/admin/approvals')}>Approval Center</button>
              <button style={buttonStyle} onClick={() => navigate('/applicant/body-type-selector')}>Body Type Selector</button>
              <button style={buttonStyle} onClick={() => navigate('/applicant/call-sphere')}>Sphere Development</button>
              <button style={buttonStyle} onClick={() => navigate('/applicant/create-chikka2')}>Create Chikka Testing</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPage;
