import React, { useState } from 'react';
import { TextField, Typography, Box, Button, Card, CardContent, CardMedia } from '@mui/material';
import { xxHash32 } from 'js-xxhash';
import { useForm } from 'react-hook-form';
import GetCity from './GetCity';

function ContactDetails() {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [chikka_id, setChikkaId] = useState('');
  const [fetchedRecord, setFetchedRecord] = useState(null);
  const [fetchedImageUrl, setFetchedImageUrl] = useState(null);
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const hashedEmail = xxHash32(emailValue, 0xCAFEBABE).toString();
    setChikkaId(hashedEmail);
    setValue("chikka_id", hashedEmail);
  };

  const handlePostRecord = async (data) => {
    try {
      console.log('Attempting to post record:', data);
      const response = await fetch('/data', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chikka_id, ...data })
      });
      if (!response.ok) throw new Error(`Failed to post record: ${response.status}`);
      const result = await response.json();
      setMessage('Record posted successfully');
      console.log('Post result:', result);
    } catch (error) {
      console.error('Error posting record:', error);
      setMessage('Failed to post record: ' + error.message);
    }
  };

  const handlePostImage = async (event) => {
    const file = event.target.files[0];
    if (!file || !chikka_id) {
      setMessage('Please enter an email and select a file');
      return;
    }

    const formData = new FormData();
    formData.append('chikka_id', chikka_id);
    formData.append('media_name', 'profile_picture');
    formData.append('image', file);

    try {
      console.log('Attempting to post image for chikka_id:', chikka_id);
      const response = await fetch('/media', {
        method: 'POST',
        body: formData
      });
      if (!response.ok) throw new Error(`Failed to post image: ${response.status}`);
      const result = await response.json();
      setMessage('Image posted successfully');
      console.log('Image post result:', result);
    } catch (error) {
      console.error('Error posting image:', error);
      setMessage('Failed to post image: ' + error.message);
    }
  };

  const handleGetRecord = async () => {
    if (!chikka_id) {
      setMessage('Please enter an email first');
      return;
    }
    try {
      console.log('Attempting to fetch record for chikka_id:', chikka_id);
      const response = await fetch(`/data/${chikka_id}`);
      if (!response.ok) throw new Error(`Failed to fetch record: ${response.status}`);
      const data = await response.json();
      setFetchedRecord(data);
      setMessage('Record fetched successfully');
      console.log('Fetched record:', data);
    } catch (error) {
      console.error('Error fetching record:', error);
      setMessage('Failed to fetch record: ' + error.message);
    }
  };

  const handleGetImage = async () => {
    if (!chikka_id) {
      setMessage('Please enter an email first');
      return;
    }
    try {
      console.log('Attempting to fetch image for chikka_id:', chikka_id);
      const response = await fetch(`/media?chikka_id=${chikka_id}&media_name=profile_picture`);
      if (!response.ok) throw new Error(`Failed to fetch image: ${response.status}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setFetchedImageUrl(url);
      setMessage('Image fetched successfully');
      console.log('Image fetched successfully');
    } catch (error) {
      console.error('Error fetching image:', error);
      setMessage('Failed to fetch image: ' + error.message);
    }
  };

  return (
    <Box mb={3}>
      <Typography variant="h5" gutterBottom>Contact Details</Typography>
      {message && <Typography color="error">{message}</Typography>}
      <form onSubmit={handleSubmit(handlePostRecord)}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          margin="normal"
          InputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "grey" } }}
          {...register("email", { required: "Email is required" })}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ backgroundColor: "#333", marginBottom: "20px" }}
          value={email}
          onChange={handleEmailChange}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="First Name"
          margin="normal"
          InputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "grey" } }}
          {...register("firstName", {
            required: "First name is required",
            minLength: { value: 2, message: "First name must be at least 2 characters" }
          })}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          sx={{ backgroundColor: "#333", marginBottom: "20px" }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Last Name"
          margin="normal"
          InputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "grey" } }}
          {...register("lastName", {
            required: "Last name is required",
            minLength: { value: 2, message: "Last name must be at least 2 characters" }
          })}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          sx={{ backgroundColor: "#333", marginBottom: "20px" }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Date of Birth (DOB)"
          type="date"
          margin="normal"
          InputLabelProps={{ shrink: true, style: { color: "grey" } }}
          {...register("dob", { required: "Date of Birth is required" })}
          error={!!errors.dob}
          helperText={errors.dob?.message}
          sx={{ backgroundColor: "#333", marginBottom: "20px" }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Phone (Optional)"
          margin="normal"
          InputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "grey" } }}
          {...register("phone")}
          sx={{ backgroundColor: "#333", marginBottom: "20px" }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <GetCity register={register} errors={errors} setValue={setValue} />

        <Box mt={3}>
          <Button type="submit" variant="contained" color="primary" style={{ marginRight: "10px" }}>
            POST RECORD
          </Button>

          <Button variant="contained" color="secondary" component="label">
            POST IMAGE
            <input type="file" hidden onChange={handlePostImage} />
          </Button>

          <Button variant="contained" color="primary" onClick={handleGetRecord} style={{ marginLeft: "10px" }}>
            GET RECORD
          </Button>

          <Button variant="contained" color="secondary" onClick={handleGetImage} style={{ marginLeft: "10px" }}>
            GET IMAGE
          </Button>
        </Box>
      </form>

      {fetchedRecord && (
        <Card sx={{ marginTop: "20px", backgroundColor: "#333" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Fetched Record</Typography>
            <pre>{JSON.stringify(fetchedRecord, null, 2)}</pre>
          </CardContent>
        </Card>
      )}

      {fetchedImageUrl && (
        <Card sx={{ marginTop: "20px", backgroundColor: "#333" }}>
          <CardMedia
            component="img"
            image={fetchedImageUrl}
            alt="Fetched Media"
            sx={{ height: 200 }}
          />
        </Card>
      )}
    </Box>
  );
}

export default ContactDetails;