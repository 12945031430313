import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircleImageGallery from './CircleImageGallery';
import useWindowSize from './shared/useWindowSize';

const LandingPage = () => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const textSettings = {
    textSize: 72,
    textX: 50,
    textY: 10,
    buttonX: 50,
    buttonY: 85,
  };

  // Adjust text size based on screen width
  const responsiveTextSize = windowSize.width < 768 ? textSettings.textSize * 0.7 : textSettings.textSize;

  // Button styles
  const buttonStyle = {
    margin: '10px',
    padding: windowSize.width < 768 ? '8px 16px' : '10px 20px',
    fontSize: windowSize.width < 768 ? '16px' : '18px',
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  // Handler for navigating to pages
  const navigateToPage = (path) => {
    navigate(path);
  };

  return (
    <div 
      className="section-home-header" 
      style={{ 
        width: '100vw', 
        height: '100vh', 
        position: 'relative', 
        backgroundColor: 'black', 
        color: 'white',
        overflow: 'hidden' 
      }}
    >
      <CircleImageGallery/>

      {/* Main Heading */}
      <h1 style={{
        position: 'absolute',
        top: `${textSettings.textY}%`,
        left: `${textSettings.textX}%`,
        zIndex: 10,
        color: 'white',
        fontSize: `${responsiveTextSize}px`,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '80%',
      }}>
        Motion Capture Models 2025!
      </h1>

      {/* Menu Buttons for Navigation */}
      <div style={{
        position: 'absolute',
        top: `${textSettings.buttonY}%`,
        left: `${textSettings.buttonX}%`,
        zIndex: 10,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <button 
          style={buttonStyle}
          onClick={() => navigateToPage('/applicant/register')}
        >
          Register Now
        </button>
        
        {/* Additional buttons to navigate to pages under development */}
        <button 
          style={buttonStyle}
          onClick={() => navigateToPage('/menu')}
        >
          Go to Menu Page
        </button>
        <button 
          style={buttonStyle}
          onClick={() => navigateToPage('/applicant/body-type-selector')}
        >
          Body Type Selector
        </button>
        <button 
          style={buttonStyle}
          onClick={() => navigateToPage('/admin/dashboard')}
        >
          Admin Dashboard
        </button>
        <button 
          style={buttonStyle}
          onClick={() => navigateToPage('/applicant/call-sphere')}
        >
          Sphere Development
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
