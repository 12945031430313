import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mockData } from './mockData';

const ChevronDown = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ChevronUp = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AdminDashboard = () => {
  const navigate = useNavigate();

  const statusOptions = [
    { name: 'Pending', color: '#FFA500' },
    { name: 'Invited', color: '#4CAF50' },
    { name: 'Confirmed', color: '#2196F3' },
    { name: 'Rejected', color: '#F44336' }
  ];

  const [statuses, setStatuses] = useState(mockData.map(() => 'Pending'));
  const [compareStates, setCompareStates] = useState(mockData.map(() => false));
  const [expandedStates, setExpandedStates] = useState(mockData.map(() => false));

  const handleCompareToggle = (index) => {
    setCompareStates((prevStates) =>
      prevStates.map((state, i) => i === index ? !state : state)
    );
  };

  const handleStatusChange = (index) => {
    setStatuses((prevStatuses) => 
      prevStatuses.map((status, i) => {
        if (i === index) {
          const currentIndex = statusOptions.findIndex(option => option.name === status);
          const nextIndex = (currentIndex + 1) % statusOptions.length;
          return statusOptions[nextIndex].name;
        }
        return status;
      })
    );
  };

  const handleExpandToggle = (index) => {
    setExpandedStates((prevStates) =>
      prevStates.map((state, i) => i === index ? !state : state)
    );
  };

  const getStatusColor = (status) => {
    return statusOptions.find(option => option.name === status)?.color || '#FFA500';
  };

  const renderApplicant = (applicant, index) => (
    <div className="applicant-card bg-white rounded-lg shadow-md overflow-hidden">
      {/* Image Container */}
      <div className="p-4">
        <img 
          src={applicant.image} 
          alt={applicant.name} 
          className="w-full rounded-lg" 
        />
      </div>

      {/* Combined Profile and Controls Container */}
      <div className="bg-gray-100 p-4">
        <h2 className="text-lg font-bold mb-2">Profile</h2>
        <hr className="border-b-2 border-blue-500 mb-4" />
        
        <div className="flex justify-between">
          <div className="flex-grow pr-4">
            <p className="font-bold text-sm">{applicant.name}</p>
            <p className="text-sm">{applicant.city}, {applicant.country}</p>
            <div className="h-1"></div>
            <p className="font-bold text-sm">{applicant.age} years old</p>
          </div>
          <div className="flex flex-col justify-between items-end" style={{ width: '120px' }}>
            <span 
              className="status-badge text-xs rounded-full inline-block w-full text-center"
              style={{ backgroundColor: getStatusColor(statuses[index]) }}
              onClick={() => handleStatusChange(index)}
            >
              {statuses[index]}
            </span>
            <label className="switch custom-switch w-full mt-2">
              <input
                type="checkbox"
                checked={compareStates[index]}
                onChange={() => handleCompareToggle(index)}
              />
              <span className="slider round">
                <span className="slider-text">Compare</span>
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* Thumbnail Container */}
      <div className="thumbnail-container">
        <hr className="border-b-2 border-grey-700 mb-4" />
        <div className="flex justify-center items-center space-x-2">
          {[1, 2, 3, 4, 5].map((num) => (
            <div key={num} className="thumbnail">
              <img 
                src={`/models/image${num}.png`} 
                //alt={`Thumbnail ${num}`} 
                className="w-full h-full object-cover rounded"
              />
            </div>
          ))}
        </div>
      </div>
      
      <button 
        className="w-full py-3 bg-gray-200 hover:bg-gray-300 transition-colors flex items-center justify-center cursor-pointer"
        onClick={() => handleExpandToggle(index)}
      >
        {expandedStates[index] ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
        <span className="ml-2">{expandedStates[index] ? 'Collapse' : 'Expand'}</span>
      </button>
  
      {expandedStates[index] && (
        <div className="p-4 md:p-6">
          <h3 className="text-lg font-bold mb-2">Contact Information</h3>
          <hr className="border-t-2 border-blue-500 mb-2" />
          <p className="text-base">{applicant.email}</p>
          <p className="text-base">{applicant.phone}</p>
          <div className="h-4"></div>
          <p className="font-bold text-base">Instagram:</p>
          <p className="text-base">{applicant.instagram}</p>
          <div className="h-4"></div>
          <p className="font-bold text-base">Twitter:</p>
          <p className="text-base">{applicant.twitter}</p>
          <p className="font-bold text-base mt-2">TikTok:</p>
          <p className="text-base">{applicant.tiktok}</p>
          <div className="h-8"></div>
          <h3 className="text-lg font-bold mb-2">Marketing</h3>
          <hr className="border-t-2 border-blue-500 mb-2" />
          <p className="font-bold text-base">Campaign</p>
          <p className="text-base">{applicant.campaign}</p>
          <div className="h-4"></div>
          <p className="font-bold text-base">Engagement</p>
          <p className="text-base">{applicant.engagement}</p>
          <div className="h-4"></div>
          <p className="font-bold text-base">Status</p>
          <p className="text-base">{applicant.status}</p>
        </div>
      )}
    </div>
  );
  
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <header className="bg-gray-800 text-white p-4 mb-6 flex items-center">
        <h1 className="text-2xl">Admin Dashboard - Detailed View</h1>
      </header>
  
      <button 
        className="px-6 py-3 text-base bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mb-6"
        onClick={() => navigate('/menu')}
      >
        Back to Menu
      </button>
  
      <div className="applicant-grid">
        {mockData.map((applicant, index) => (
          <React.Fragment key={index}>
            {renderApplicant(applicant, index)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;