import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import RegistrationForm from './applicant/RegistrationForm';
import MenuPage from './MenuPage';
import AdminDashboard from './admin/AdminDashboard';
import BodyTypeSelector from './applicant/BodyTypeSelector';
import CallSphere from './applicant/CallSphere';
import ContactDetails from './applicant/ContactDetails';

const Main = () => {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/applicant/register" element={<RegistrationForm />} />
        <Route path="/applicant/body-type-selector" element={<BodyTypeSelector />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/applicant/call-sphere" element={<CallSphere />} />
        <Route path="/applicant/contact-details" element={<ContactDetails />} />
        {/* Other routes remain unchanged */}
      </Routes>
    </div>
  );
};

export default Main;
