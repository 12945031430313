import React from 'react';
import useWindowSize from './shared/useWindowSize';

const CircleImageGallery = () => {
  const windowSize = useWindowSize();

  const settings = {
    tiltX: -12,
    tiltY: -30,
    radius: 700,
    spinSpeed: 20,
    size: 300,
    opacity: .9,
    positionX: 68,
    positionY: 15,
  };

  const images = [
    '/models/image1.png',
    '/models/image2.png',
    '/models/image1.png',
    '/models/image2.png',
    '/models/image4.png',
    '/models/image5.png',
    '/models/image1.png',
    '/models/image2.png',
  ];

  // Adjust radius and size based on screen width
  const responsiveRadius = windowSize.width < 768 ? settings.radius * 0.75 : settings.radius;
  const responsiveSize = windowSize.width < 768 ? settings.size * 0.75 : settings.size;

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'black' }}>
      <div className="circle-wrapper" style={{ 
        perspective: '1000px',
        position: 'absolute',
        left: `${settings.positionX}%`,
        top: `${settings.positionY}%`,
        transform: `translate(-50%, -50%) rotateX(${settings.tiltX}deg) rotateY(${settings.tiltY}deg)`,
      }}>
        <div className="circle-container" style={{
          animation: `rotate ${settings.spinSpeed}s infinite linear`
        }}>
          <div className="circle-block">
            {images.map((image, index) => (
              <div 
                key={index}
                className={`circle-item _${index + 1}`}
                style={{
                  opacity: settings.opacity,
                  transform: `rotateY(${index * (360 / images.length)}deg) translateZ(${responsiveRadius}px)`,
                  width: `${responsiveSize}px`,
                  overflow: 'hidden', // Ensures the borderRadius applies to backgroundImage
                  borderRadius: '5px', // Optional: adds rounded corners
                  height: `${responsiveSize}px`,
                }}
              >
                <img src={image} alt={`Image ${index + 1}`} className="cover-image" loading="lazy" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Sliders have been commented out */}
    </div>
  );
};

export default CircleImageGallery;