import React, { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import axios from 'axios';
import ContactDetails from './ContactDetails';

function RegistrationForm() {
  const [dropdownData, setDropdownData] = useState(null);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get('/dat/dropdown.json');
        setDropdownData(response.data);
      } catch (error) {
        console.error('Error fetching dropdown data', error);
      }
    };
    fetchDropdownData();
  }, []);

  const handleSubmit = async (data) => {
    console.log("Submitting data:", data);
    // Here you would typically send the data to your backend
    // For now, we'll just log it
  };

  return (
    <Container maxWidth="sm" className="bg-black text-white p-8 rounded-lg shadow-lg">
      <Typography variant="h4" gutterBottom align="center" className="special-text">
        Registration Form - Backend Test
      </Typography>
      <ContactDetails onSubmit={handleSubmit} dropdownData={dropdownData} />
    </Container>
  );
}

export default RegistrationForm;