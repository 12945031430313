import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const CallSphereWithShimmeringGold = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene, Camera, Renderer setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x202020); // Dark grey background

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 50;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Add an environment texture for reflections
    const loader = new THREE.CubeTextureLoader();
    const envMap = loader.load([
      'https://threejs.org/examples/textures/cube/skybox/px.jpg', // Right
      'https://threejs.org/examples/textures/cube/skybox/nx.jpg', // Left
      'https://threejs.org/examples/textures/cube/skybox/py.jpg', // Top
      'https://threejs.org/examples/textures/cube/skybox/ny.jpg', // Bottom
      'https://threejs.org/examples/textures/cube/skybox/pz.jpg', // Front
      'https://threejs.org/examples/textures/cube/skybox/nz.jpg', // Back
    ]);
    scene.environment = envMap; // Set environment map for the scene

    // Gold-like material using MeshStandardMaterial
    const goldMaterial = new THREE.MeshStandardMaterial({
      color: 0xffd700, // Gold color
      metalness: 1, // Fully metallic
      roughness: 0.4, // Increase roughness for more realistic reflections
      envMap: envMap,
      envMapIntensity: 1,
    });

    // Geometry (sphere)
    const geometry = new THREE.SphereGeometry(15, 32, 16);
    const sphere = new THREE.Mesh(geometry, goldMaterial);
    scene.add(sphere);

    // Create the wireframe separately using LineSegments
    const wireframeGeometry = new THREE.WireframeGeometry(geometry);
    const wireframeMaterial = new THREE.LineBasicMaterial({ color: 0xffd700 }); // Gold color
    const wireframe = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);
    scene.add(wireframe);

    // Lighting
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // Reduce intensity
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.4); // Increase intensity
    scene.add(ambientLight);

    // Orbit Controls for Mouse Interaction
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Spin the sphere and wireframe
      sphere.rotation.y += 0.01; // Spin around the y-axis
      wireframe.rotation.y += 0.01; // Spin the wireframe as well

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Clean up on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
      renderer.dispose();
      controls.dispose();
    };
  }, []);

  return <div ref={mountRef} style={{ width: '100vw', height: '100vh' }} />;
};

export default CallSphereWithShimmeringGold;
